
import CrudTable from "@/components/admin/CrudTable.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { capitalizeFirst } from "@/utils";
@Component({
  components: { CrudTable },
})
/**
 * CRUD detail view component
 */
export default class CrudDetail extends Vue {
  @Prop({ required: true, type: String })
  readonly slug!: string;
  // Boolean indicates if the page is loading due to data fetching
  private isLoading = true;
  // Boolean indicates if errors are raised
  private displayError = false;
  // String containing the name of the module that handle this entity in the state manager
  private storeModuleName = "";
  // String containing the name of the entity list holding by the state manager
  private stateEntityList = "";
  // String describing user-friendly name of the entity
  private title = "";

  created(objectToCreate: Record<string, any>): void {
    let entityData = this.$store.state.creationToolsTabs.find(
      (route: Record<string, string>) => route.slug == `crud/${this.slug}`
    );
    this.storeModuleName ||= this.slug;
    this.title ||= entityData.title;
    this.stateEntityList ||= entityData.stateEntityList;

    this.$store.dispatch(`${this.storeModuleName}/${this.fetchingAction}`, {
      [this.storeModuleName]: objectToCreate,
      callback: (success: boolean) =>
        success ? (this.isLoading = false) : (this.displayError = true),
    });

    this.$store.dispatch("user/fetchPoweredUsers");
  }

  /**
   * Return entity keys
   */
  get entityKeys(): Array<string> {
    return this.$store.state[this.storeModuleName][this.stateEntityList][0]
      ? Object.keys(
          this.$store.state[this.storeModuleName][this.stateEntityList][0]
        )
      : [];
  }
  /**
   * Return array of entities
   */
  get entityData(): Array<Record<string, any>> {
    return this.$store.state[this.storeModuleName][this.stateEntityList]
      ? this.$store.state[this.storeModuleName][this.stateEntityList]
      : [];
  }
  /**
   * Return the action name that fetches entity list
   */
  get fetchingAction(): string {
    return `fetch${capitalizeFirst(this.stateEntityList)}`;
  }
  /**
   * Return the action name that create entity
   */
  get createAction(): string {
    return `create${capitalizeFirst(this.storeModuleName)}`;
  }
  /**
   * Return the action name that update entity
   */
  get updateAction(): string {
    return `update${capitalizeFirst(this.storeModuleName)}`;
  }
  /**
   * Return the action name that delete entity
   */
  get deleteAction(): string {
    return `delete${capitalizeFirst(this.storeModuleName)}`;
  }

  /**
   * Creates an instance of the entity
   * @param entity
   */
  createHandler(entity: Record<string, any>): void {
    this.$store.dispatch(`${this.storeModuleName}/${this.createAction}`, {
      [this.storeModuleName]: entity,
      // TODO : remove log
      callback: (createdInstance: any) => console.log(createdInstance),
    });
  }

  /**
   * Updates an existing instance of the entity
   * @param entity
   * @param entityId
   */
  updateHandler(entity: Record<string, any>, entityId: string): void {
    this.$store.dispatch(`${this.storeModuleName}/${this.updateAction}`, {
      [this.storeModuleName]: entity,
      id: entityId,
      // TODO : remove log
      callback: (arg: any) => console.log(arg),
    });
  }

  /**
   * Deletes an existing instance of the entity
   * @param entityId
   */
  deleteHandler(entityId: string): void {
    this.$store.dispatch(`${this.storeModuleName}/${this.deleteAction}`, {
      id: entityId,
      // TODO : remove log
      next: (arg: boolean) => console.log(arg),
    });
  }
}
